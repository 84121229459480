import { useState } from "react"
import Infos from "./Infos"
import Modal from "./Modal"

const Wall = ({ isFull, templateInfos, wall }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalInfos, setModalInfos] = useState(null)

  const openModal = (el) => {
    setModalOpen(true)
    setModalInfos(el)
  }

  return (
    <div>
      <div className={isFull ? 'text-center' : ''}>
        <h1 className="landing__title">{templateInfos.title}</h1>
        <p className="landing__intro" dangerouslySetInnerHTML={{__html: templateInfos.subtitle}}></p>
      </div>

      <ul className={`landing-wall landing-wall--${isFull ? 'full' : 'sidebar' }`}>
        { wall?.map((el, i) => {
          el.platform = el.permalink.includes('www.tiktok.com') ? 'tiktok' : 'insta'

          return (
            <li key={i} className="landing-wall__item">
              <button onClick={() => openModal(el)}>
                { el.type === 'VIDEO'
                  ? <video loading="lazy" className="w-full">
                      <source src={el.path} />
                    </video>
                  : <img loading="lazy" className="w-full" src={el.path} alt="" />
                }

                <div className="landing-wall__hover">
                  <Infos
                    platform={el.platform}
                    likes={el.likeCount}
                    comments={el.commentsCount}
                    date={el.timestamp}
                    user={el.authorUsername ?? el.authorName}
                  />
                </div>
              </button>
            </li>
          )
        }) }
      </ul>

      { modalOpen &&
        <Modal modalInfos={modalInfos} setModalOpen={setModalOpen} modalOpen={modalOpen} />
      }
    </div>
  )
}

export default Wall